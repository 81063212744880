import { styled } from '@mui/material'

const StyledSvg = styled('svg')({ cursor: 'pointer', borderRadius: '50%' })

export default function SwitchButton({ disabled }: { disabled?: boolean }) {
  return (
    <StyledSvg
      sx={{
        '&:hover circle': {
          strokeOpacity: disabled ? 0.4 : 1
        }
      }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" fill="black" />
      <path
        d="M13.0182 6.00028C12.8964 5.99666 12.7764 6.02803 12.6751 6.08999C12.5737 6.15195 12.4962 6.24138 12.4534 6.34571C12.4105 6.45004 12.4045 6.56403 12.4362 6.67165C12.4678 6.77927 12.5356 6.87511 12.6299 6.9457L15.5142 9.20402H6.59593C6.51829 9.20247 6.4411 9.21507 6.36887 9.24116C6.29665 9.26725 6.23085 9.3063 6.17534 9.35596C6.11983 9.40561 6.07573 9.46488 6.04562 9.53033C6.01551 9.59578 6 9.66604 6 9.73705C6 9.80805 6.01551 9.87838 6.04562 9.94383C6.07573 10.0093 6.11983 10.0685 6.17534 10.1182C6.23085 10.1679 6.29665 10.2069 6.36887 10.233C6.4411 10.2591 6.51829 10.2717 6.59593 10.2701H17.0936C17.2121 10.2694 17.3276 10.2357 17.4246 10.1735C17.5217 10.1112 17.5957 10.0234 17.6367 9.92175C17.6778 9.82008 17.684 9.70934 17.6545 9.60436C17.625 9.49938 17.5612 9.40511 17.4716 9.33413L13.3917 6.13463C13.2885 6.05083 13.1561 6.00325 13.0182 6.00028ZM6.59593 13.7369C6.47739 13.7376 6.36191 13.7713 6.26487 13.8335C6.16783 13.8958 6.09385 13.9836 6.05278 14.0853C6.01172 14.187 6.00552 14.2977 6.03502 14.4026C6.06451 14.5076 6.1283 14.6019 6.21788 14.6729L10.3024 17.8724C10.3606 17.9178 10.4281 17.9523 10.5009 17.9739C10.5738 17.9956 10.6506 18.0039 10.7269 17.9983C10.8032 17.9928 10.8777 17.9736 10.9459 17.9418C11.0141 17.91 11.0748 17.8663 11.1245 17.813C11.1742 17.7598 11.212 17.6981 11.2357 17.6315C11.2593 17.5649 11.2684 17.4947 11.2623 17.4249C11.2563 17.3551 11.2353 17.287 11.2005 17.2246C11.1657 17.1622 11.1179 17.1068 11.0596 17.0613L8.17644 14.803H17.0936C17.1713 14.8046 17.2485 14.792 17.3208 14.766C17.3931 14.7399 17.4589 14.7009 17.5145 14.6512C17.57 14.6016 17.6142 14.5423 17.6443 14.4768C17.6745 14.4113 17.69 14.341 17.69 14.2699C17.69 14.1989 17.6745 14.1285 17.6443 14.063C17.6142 13.9976 17.57 13.9383 17.5145 13.8886C17.4589 13.8389 17.3931 13.7999 17.3208 13.7739C17.2485 13.7478 17.1713 13.7352 17.0936 13.7369H6.59593Z"
        fill="white"
      />
    </StyledSvg>
  )
}
